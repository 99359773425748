import React, {useState, useCallback, useMemo} from 'react';
import Image from 'next/image';
import debounce from "lodash.debounce";

interface Props {
  message?: string | React.ReactNode,
  img?: any,
  onClick?: any,
  forceShowTooltip?: boolean,
  w?: number,
  h?: number,
  customComponent?: any,
  customRenderedComponent?: any,
  positionBottom?: boolean;
  wrapperClass?: string;
  textWrapperClass?: string;
  messageWrapperClass?: string;
  position?: string;
  xOffsetClass?: string;
  yOffsetClass?: string;
  impressionEventCallbackOnHover?: () => void;
}

// const getTitle = (message: string | React.ReactNode) => {
//   if(typeof message === 'object') return "";
//   return message;
// }
function Tooltip (props: Props) {
  const {
      message,
      img,
      onClick,
      forceShowTooltip,
      w,
      h,
      customComponent,
      customRenderedComponent,
      wrapperClass = '',
      textWrapperClass = '',
      messageWrapperClass = '',
      position = 'top',
      xOffsetClass,
      yOffsetClass,
      impressionEventCallbackOnHover,
  } = props;
  const [hover, setHover] = useState(false);

  const onMouseEnter = debounce(() => {
    impressionEventCallbackOnHover && impressionEventCallbackOnHover();
  }, 500);

  const handleMouseInOut = useCallback((e) => {
    if (e.type === 'mouseover') {
      setHover(true);
    } else {
      setHover(false);
    }
  }, []);

  const getStyleAsPerPosition = useMemo(() => {
    switch (position) {
      case 'top':
        return `${yOffsetClass || 'top-0'} ${xOffsetClass || 'left-1/2'} -translate-x-1/2 -translate-y-full`;
      case 'bottom':
        return `${yOffsetClass || 'bottom-0'} ${xOffsetClass || 'left-1/2'} -translate-x-1/2 translate-y-full`;
      case 'right':
        return `${xOffsetClass || 'right-0'} translate-x-full -translate-y-1/2 ${yOffsetClass || 'top-1/2'}`;
      case 'left':
        return `${xOffsetClass || 'left-0'} -translate-x-full -translate-y-1/2 ${yOffsetClass || 'top-1/2'}`;
      default:
        return '';
    }
  }, [position, xOffsetClass, yOffsetClass]);


  const getNotchStyleAsPerPosition = useMemo(() => {
    switch (position) {
      case 'top':
        return `after:absolute after:block after:content-[''] after:bg-theme_1_grey1 after:left-1/2 after:bottom-[-5px] after:transform after:rotate-45 after:translate-x-[-50%] after:w-[10px] after:h-[10px] after:shadow-[0px_0px_6px_rgba(0,0,0,0.25)]`;
      case 'bottom':
        return `after:absolute after:block after:content-[''] after:bg-theme_1_grey1 after:left-1/2 after:top-[-5px] after:transform after:rotate-45 after:translate-x-[-50%] after:w-[10px] after:h-[10px] after:shadow-[0px_0px_6px_rgba(0,0,0,0.25)]`;
      case 'right':
        return `after:absolute after:top-[50%] after:block after:content-[''] after:bg-theme_1_grey1 after:left-[-5px] after:transform after:rotate-[45deg] after:translate-y-[-50%] after:w-[10px] after:h-[10px] after:shadow-[0px_0px_6px_rgba(0,0,0,0.25)]`;
      case 'left':
        return `after:absolute after:top-[50%] after:block after:content-[''] after:bg-theme_1_grey1 after:right-[-5px] after:transform after:rotate-[-45deg] after:translate-y-[-50%] after:w-[10px] after:h-[10px] after:shadow-[0px_0px_6px_rgba(0,0,0,0.25)]`;
      default:
        return '';
    }
  }, [position]);

  const isMessage = Boolean(message || customComponent || customRenderedComponent);

  return (
    <div className={`relative w-fit ${wrapperClass || ''}`}
         onMouseEnter={onMouseEnter}
    >
      {customComponent
        ? (React.cloneElement(customComponent, {
          onMouseOver: isMessage ? handleMouseInOut : () => { },
          onMouseOut: isMessage ? handleMouseInOut : () => { },
          className: `${customComponent.props.className} w-fit`,
        }))
        : (
          <Image
            src={img}
            width={w || '16'}
            height={h || '16'}
            onMouseOver={isMessage ? handleMouseInOut : () => { }}
            onMouseOut={isMessage ? handleMouseInOut : () => { }}
            onClick={onClick}
            className={'m-auto cursor-pointer w-fit'}
            alt='tooltipImg'
          />
        )}
      <div
        className={`absolute ${getStyleAsPerPosition} text-[12px] p-[10px] bg-none w-max z-10 ${(forceShowTooltip || hover) ? 'block' : 'hidden'} ${textWrapperClass || ''}`}
        onMouseOver={isMessage ? handleMouseInOut : () => { }}
        onMouseOut={isMessage ? handleMouseInOut : () => { }}
      >
        {customRenderedComponent ? customRenderedComponent : ''}
        {!customRenderedComponent ? <div className={`relative text-center max-w-[275px] text-theme_1_white px-[10px] py-[6px] bg-theme_1_grey1 rounded-[3px] shadow-[0px_0px_6px_rgba(0,0,0,0.25)] ${getNotchStyleAsPerPosition} ${messageWrapperClass}`}>{message}</div> : ''}
      </div>
    </div>
  );
}

export default Tooltip;
